




















































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue';
import {
  Groups,
  User,
  Global,
  MapSettings,
  Schedules,
  Props
} from '@/store';
import cloneDeep from 'lodash/cloneDeep';

@Component({
  components: { ConfirmDialog }
})
export default class GroupsList extends Vue {
  @User.State('project') project;
  @Groups.State('list') groups;
  @Groups.Action('remove') removeGroup;
  @Global.State('readonly_user') readonly_user;
  @Global.State('notify') notify;
  @Global.State('lang') lang;
  @Global.Mutation('setPageTitle') setPageTitle;
  @MapSettings.State('userMapFilters') userMapFilters;
  @MapSettings.Action('updateFiltersProperty') updateFiltersProperty;
  @MapSettings.Mutation('setUserMapFilters') setUserMapFilters;
  @Schedules.Action('loadSchedules') loadSchedules;
  @Schedules.State('schedules') schedules;
  @Schedules.Action('runSchedulerInServer') runSchedulerInServer;
  @Props.Action('editProp') edit;
  @Props.State('list') projectProperties;
  @Props.Action('load') loadProjectProperties;

  isLoading = true;
  loadingDelete = false;
  deleteDialog = false;
  groupData = {};
  pagination = { sortBy: 'date', descending: true, rowsPerPage: 10 };
  localFilters = [];
  localSchedules = [];
  currentGroups = null;

  async mounted() {
    this.setPageTitle('Group Management');
    this.isLoading = true;
    this.currentGroups = this.groups;
    this.isLoading = false;
  }

  get headers() {
    return [
      {
        text: 'Name',
        value: 'name'
      },
      {
        text: 'Description',
        value: 'description'
      },
      {
        text: 'Assigned devices',
        value: 'devices'
      },
      {
        text: 'Actions',
        value: 'id',
        sortable: false
      }
    ];
  }

  @Watch('pagination')
  watchPagination() {
    if (this.pagination.sortBy === 'devices') {
      if (this.pagination.descending) {
        this.currentGroups.sort((a, b) => a.total_devices > b.total_devices ? 1 : -1);
      } else {
        this.currentGroups.sort((a, b) => a.total_devices > b.total_devices ? -1 : 1);
      }
    }
  }

  @Watch('$store.state.Groups.list')
  async setGroups() {
    if (this.groups.length > 0) {
      this.currentGroups = this.groups;
    }
  }

  async removeGroupAction() {
    this.loadingDelete = true;
    await this.removeGroupFromFilters();
    // if (this.notify && this.notify.type === 'error') {
    //   this.loadingDelete = false;
    //   this.deleteDialog = false;
    //   return;
    // }

    await this.removeGroupFromSchedules();
    // if (this.notify && this.notify.type === 'error') {
    //   await this.updateFilters(this.localFilters);
    //   this.loadingDelete = false;
    //   this.deleteDialog = false;
    //   return;
    // }
    await this.removeGroup(this.groupData);
    // if (this.notify && this.notify.type === 'error'){
    //   await this.updateFilters(this.localFilters);
    //   await this.updateSchedules(this.localSchedules);
    //   this.loadingDelete = false;
    //   this.deleteDialog = false;
    //   return;
    // }

    this.loadSchedules();
    this.loadProjectProperties({ projectId: this.project.id });
    this.runSchedulerInServer();

    this.loadingDelete = false;
    this.deleteDialog = false;
  }

  async removeGroupFromFilters() {
    this.localFilters = cloneDeep(this.userMapFilters);
    const new_filters = this.userMapFilters
      .filter((filter) => filter.name !== 'default')
      .map((filter) => {
        if (filter.groups.includes(this.groupData['groupID'])) {
          if (filter.groups.length === 1) return null;
          return {
            ...filter,
            groups: filter.groups.filter(
              (group_id) => group_id !== this.groupData['groupID']
            )
          };
        }
        return filter;
      })
      .filter((filter) => filter);

    await this.updateFilters(new_filters);
  }

  async updateFilters(filters) {
    const filters_data = {
      projectId: this.project.id,
      routes: filters
    };
    await this.updateFiltersProperty(filters_data);
  }

  async removeGroupFromSchedules() {
    if (!this.schedules.length)
      await this.loadSchedules({ projectId: this.project.id });
    this.localSchedules = cloneDeep(this.schedules);
    const new_schedules = this.schedules
      .map((schedule) => {
        if (
          schedule.groups &&
          schedule.groups.length &&
          schedule.groups.includes(this.groupData['groupID'])
        ) {
          if (schedule.groups.length === 1) return null;
          return {
            ...schedule,
            groups: schedule.groups.filter(
              (group_id) => group_id !== this.groupData['groupID']
            )
          };
        }
        return schedule;
      })
      .filter((schedule) => schedule);
    await this.updateSchedules(new_schedules);
  }

  async updateSchedules(schedules) {
    await this.edit({
      objectType: 'projects',
      objectID: this.project.id,
      name: 'dashboard.schedules',
      value: schedules
    });
  }

  deleteGroup(groupID, groupName) {
    if (groupID) {
      const data = {
        projectID: this.project.id,
        groupID,
        groupName
      };
      this.deleteDialog = true;
      this.groupData = data;
    }
  }
}
